<template>
  <div id="protocol" class="pol">
    <div class="sub-title f-b">{{app_name}}用户服务协议</div>
    <div class="desc ">
      <p class="f-b">更新时间：【2022】年【6】月【27】日</p>
      <p class="f-b">生效时间：【2022】年【6】月【27】日</p>

      <p class="f-b">尊敬的用户：</p>
      <p>您好！</p>
      <p>感谢您选择佳伴软件产品与服务（简称“佳伴”或“佳伴服务”）。在注册佳伴账号及使用佳伴服务前，请用户审慎阅读、充分理解以下所述条款的内容，特别是限制、排除或者免除责任的条款。</p>
      <p>
        本服务协议的所有条款即构成贵方与成海南悠蓝网络科技有限公司（简称“悠蓝网络”）之间关于贵方使用佳伴服务所达成的协议（简称“本协议”），用于规范双方业务行为，明确双方的权利和义务，保障双方合法权益。用户在使用佳伴服务之前，请仔细阅读本协议，如用户不同意本服务协议的任何条款，请不要使用佳伴产品与服务。贵方勾选或点击“我已阅读并同意《佳伴服务协议》”按钮或以第三方平台账号登录或实际使用佳伴服务，即视为贵方已接受本协议的全部条款，本协议即开始生效。</p>
    </div>
    <div class="lists">
      <p  class="f-b">1.定义

      </p>
      <p>1.1
        “佳伴”，是指悠蓝网络及其关联公司经营的一款陌生人网络社交产品（包括但不限于悠蓝网络经营的quaiapp.com网站、佳伴电脑端及移动客户端软件等），为用户提供数据存储、管理、分享的信息存储空间服务和互联网直播服务等。

      </p>
      <p> 1.2 “用户”，是指任何使用佳伴及/或通过佳伴搜索、收听、上传、下载、传播、发布内容的使用者，包括但不限于法人、其他组织或自然人。

      </p>
      <p>
        1.3“用户内容”，是指由用户使用佳伴上传、发布的其拥有完整著作权及相关合法权利的录音制品（包括但不限于用户或第三方录制的原创音频、音乐作品音频、影视类作品音频、文字作品的有声读物等），以及与之相关的声音名称、声音封面、用户名、用户肖像、声音内容简介等。


      </p>
      <p class="f-b"> 2. 协议生效及变更

      </p>
      <p> 2.1所有拟使用、使用佳伴全部或部分服务的用户，应当首先同意本协议的所有条款，特别是免除或者限制责任的条款，限制、免责条款可能以加粗形式提示贵方注意。如果贵方不接受本协议，请不要使用佳伴全部或部分服务。

      </p>
      <p> 2.2即使用户在注册账号过程中没有选择“已查看并同意《佳伴服务协议》”，贵方实际访问、浏览及使用佳伴提供的各项服务、业务的行为，即视为对本协议的完全接受，并同意受本协议的约束。
      </p>
      <p> 2.3 本协议是用户与悠蓝网络之间关于用户使用佳伴服务所订立的协议，在用户开通或使用某项特定服务时，可能会涉及某项服务的单独协议，所有单独协议为本协议不可分割的组成部分，与本协议具有同等法律效力。

      </p>
      <p> 2.4
        悠蓝网络保留依自主判断在将来的任何时间变更、修改、增加或删除本协议的权利，一旦发生服务条款的变动，悠蓝网络将公示修改的内容，所有修改的内容均构成本协议的一部分。当用户使用佳伴的任何服务时，应接受佳伴随时提供的与该服务相关的规则或说明，并且此规则或说明均构成本服务条款的一部分。如果贵方不同意服务条款的修改，请立即主动取消、停止已经获得的服务；如果贵方继续使用佳伴服务，则视为贵方已经接受服务条款的修改。


      </p>
      <p class="f-b"> 3. 信息存储空间服务郑重声明

      </p>
      <p> 3.1
        悠蓝网络郑重提请您注意，佳伴向用户提供录音技术服务及信息网络存储空间服务，用户有权使用佳伴信息存储空间服务，内容均由用户录制、上传及发布，包括录制音频、背景音乐、曲库、内容均由用户自行提供。用户承诺保证对其上传、发布的内容享有著作权及相关权利，不存在任何侵犯第三方合法权益（包括但不限于著作权、商标权、专利权等）的情形，并保证其拥有合法充分的权利将内容通过佳伴信息存储空间服务上传、发布、使用、复制、传播和供用户搜索、收听和下载。内容如涉及到第三方的合法权利，用户应在上传和发布之前获得相关权利人的授权。

      </p>
      <p> 3.2
        悠蓝网络非常重视和尊重版权及其他知识产权的保护，悠蓝网络已在本协议及特定协议中约定用户使用佳伴服务不得侵害他人之合法权益，并尽商业上合理的注意义务设置相应的技术措施及规范防止用户通过佳伴服务侵权他人之合法权益。

      </p>
      <p>
        如用户发现佳伴中之任何内容或用户使用佳伴服务产生的内容涉嫌侵害其合法权益的，应按照《信息网络传播权保护条例》有关规定及时发送邮件到aizhuiguang@163.com并提供权属证明、初步侵权证据等所需材料。悠蓝网络将在收到权利通知邮件后的按照佳伴平台管理规定进行处罚。

      </p>
      <p> 3.3 如用户所上传、发布的内容存在违法或侵权情形的，悠蓝网络在收到权利人之权利通知后会依法移除内容，并有权采取包括但不限于以下处罚措施：警告、限制或禁止使用全部或部分服务功能、暂时或永久封禁账号。

      </p>
      <p>3.5
        因内容引起的第三方权利主张，由用户自行解决并承担全部的法律责任，与悠蓝网络无关。若因内容侵权导致悠蓝网络承担赔偿责任的，用户应赔偿悠蓝网络所遭受的所有损失，包括但不限于：赔偿款、诉讼费、律师费、公证费、差旅费等直接、间接损失。


      </p>
      <p class="f-b">4. 用户使用规则

      </p>
      <p>4.1 用户资格
      </p>
      <p> 在您开始使用 / 注册程序使用我方平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。
      </p>
      <p> 若您不具备前述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。特别地，如果您是未成年人，请在您的监护人的同意和指导下访问和 / 或使用我方平台网站。

      </p>
      <p>4.2 非商业使用
      </p>
      <p>用户承诺，未经悠蓝网络书面同意，用户不得利用佳伴服务进行销售或其他商业使用等用途。

      </p>
      <p> 4.3 合法使用
      </p>
      <p> （1）用户使用佳伴服务时必须遵守中华人民共和国法律、法规和政策，不得利用佳伴服务制作、复制、发布、传播如下内容：
      </p>
      <p>（a）反对宪法所确定的基本原则的；
      </p>
      <p>（b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      </p>
      <p>（c）损害国家荣誉和利益的；
      </p>
      <p>（d）破坏国家宗教政策，宣扬邪教和封建迷信的；
      </p>
      <p>（e）散布谣言，扰乱社会秩序，破坏社会稳定的；
      </p>
      <p>（f）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      </p>
      <p>（g）侮辱或者诽谤他人，侵害他人合法权益的；
      </p>
      <p>（h）含有法律、法规和政策禁止的其他内容的信息。

      </p>
      <p>（2）悠蓝网络一直致力于为用户提供文明、健康、规范有序的网络环境，用户不得利用悠蓝网络发布以下内容或从事以下行为：
      </p>
      <p>（a）诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；
      </p>
      <p>（b）色情网站、成人用品网站等含有任何性或性暗示的内容；
      </p>
      <p>（c）国家明令禁止或未取得国家许可销售的产品，或假冒、伪劣产品或未经授权销售的产品；
      </p>
      <p>（d）组织、宣传网络兼职，或虚假人气、贩卖虚拟货币，或骚扰、垃圾广告或信息的；
      </p>
      <p>（e）涉及他人隐私、个人信息或资料的；
      </p>
      <p>（f）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
      </p>
      <p> （g）其他侵犯其他用户或第三方合法权益的。

      </p>
      <p> （3）用户不得利用佳伴服务实施以下行为：
      </p>
      <p>（a) 未经悠蓝网络书面许可，对佳伴软件进行反向工程、反向汇编、反向编译或以其他方式获取佳伴软件源代码；
      </p>
      <p>
        （b）未经悠蓝网络书面许可，对于佳伴软件相关信息，擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、借助佳伴软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等删除佳伴软件及其他副本上所有关于版权的信息、内容；
      </p>
      <p> （c）一切非法利用第三方平台、工具或违反平台相关规定或其他不正当手段对佳伴平台所有服务产品或活动/比赛进行刷单、刷粉、点赞、评论等行为；
      </p>
      <p> （d）使用佳伴服务过程中，对佳伴服务交互数据进行复制、更改、修改的；
      </p>
      <p> （e）利用佳伴服务推广其他第三方运营平台或互相推广；
      </p>
      <p>（f）提交、发布虚假信息，或冒充、利用他人名义；
      </p>
      <p>（g）诱导、欺骗、强迫其他用户关注、点击链接页面或分享信息；
      </p>
      <p>（h）虚构、编造、散布虚假或片面事实，欺骗、误导他人的；
      </p>
      <p>（i）注册信息、申请认证资料与实际情况不一致的；
      </p>
      <p>（j）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播的；
      </p>
      <p>（k）其他利用佳伴服务从事违反、犯罪活动，或任何干扰佳伴服务正常运营，或违反佳伴平台规则和要求的；

      </p>
      <p class="f-b">4.4 用户账号使用规范
      </p>
      <p> （1）佳伴平台为中国移动/中国电信手机用户提供通过手机号码一键登录的快捷方式。当用户选择中国移动/中国电信一键登录方式录佳伴账户后，即可使用佳伴服务。
      </p>
      <p> （2）用户应确保注册资料的真实性和准确性，并保证仅使用其个人信息或依法获得授权的信息注册佳伴账号。如注册资料有任何变动，须及时更新，以便悠蓝网络确认贵方的身份，或为贵方提供密码找回等服务。
      </p>
      <p> （3）用户根据要求完成注册申请后，可获得特定佳伴账号的使用权。
      </p>
      <p> （4）悠蓝网络特别提醒贵方妥善保管您的用户名和密码，不要将您的账号信息透露给任何其他人，不要向他人透露、复制、转让注册账户或授权许可他人使用注册账户。当使用完佳伴服务后，应安全退出。
      </p>
      <p>
        （5）您通过用户名、密码或手机号、验证码或您同意授权的第三方平台账户登录其佳伴账户的，悠蓝网络将该登录行为视为系贵方亲自实施。用户发现任何账号异常情况，应立即通知悠蓝网络请求采取应急措施，但该账号登录后可能导致的损失由贵方承担，悠蓝网络不负任何责任。
      </p>
      <p>
        （6）如果用户超过180天未使用、或停止使用本服务或服务被终止或取消，悠蓝网络有权回收账号且无需事先通知贵方即可从服务器上永久地删除用户的数据。服务停止、终止或取消后，悠蓝网络没有义务向用户返还任何数据。用户同意悠蓝网络有权行使上述权利且不需对用户或第三方承担任何责任。

      </p>
      <p class="f-b"> 4.5 关于用户名和昵称使用：
      </p>
      <p> （1）不得使用党和国家领导人或其他知名人士的真实姓名、字号、艺名、笔名作为用户名和昵称；
      </p>
      <p> （2）不得使用国家机构或其他机构的名称作为用户名和昵称；
      </p>
      <p> （3）不得使用和其他知名网友之名相同或近似的用户名和昵称；
      </p>
      <p> （4）不得使用不文明、不健康，或带攻击性、侮辱性的用户名和昵称；
      </p>
      <p> （5）请勿使用易产生歧义、引起他人误解的用户名和昵称；
      </p>
      <p> （6）不得使用其他具有不良影响的用户名和昵称；
      </p>
      <p> 对于违反以上规定或产生不良后果的用户名和昵称，悠蓝网络有权删除而不必事先通知。

      </p>
      <p class="f-b"> 4.6 关于发表及评论内容规则
      </p>
      <p> （1）遵守相关法规，严禁发表违反法律法规及社会主义道德规定的内容；
      </p>
      <p>（2）使用文明用语，不得张贴对任何人进行人身攻击、谩骂、诋毁的言论；
      </p>
      <p>（3）不得张贴未经公开报道、未经证实的消息；
      </p>
      <p>（4）不得张贴与所在论坛主题无关的消息、言论和图片；
      </p>
      <p>（5）转贴文章应注明原始出处和时间；
      </p>
      <p> 对于违反以上规定的内容，在不需要通知和解释的情况下，悠蓝网络有权予以删除，并根据违规情节作出处罚措施。

      </p>
      <p class="f-b"> 4.7 关于贴图规则
      </p>
      <p>（1）不得张贴宣扬反动、封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪等不符合国家法律规定的以及任何包含种族、性别、宗教歧视性和猥亵性的图片；
      </p>
      <p>（2）不得出现带有侮辱性、挑衅、辱骂其他人以及不健康内容的图片；
      </p>
      <p> 请勿使用易产生歧义、引起他人误解的图片；
      </p>
      <p> （3）摘录、转贴的图片请注明出处及作者，禁止张贴侵犯他人著作权、版权等知识产权的图片；
      </p>
      <p> 悠蓝网络有权在不需要通知和解释的情况下删除违反以上规定的图片，并根据违规情节作出处罚。


      </p>
      <p class="f-b"> 5. 知识产权

      </p>
      <p> 5.1
        佳伴由悠蓝网络独立自主开发完成，其产品、服务所涉及的著作权、商标权、专利权、商业秘密等知识产权、其他相关权利均独立归属悠蓝网络所有。与佳伴服务相关的所有信息，包括但不限于：佳伴商标标识、网页/产品版面设计、界面设计、图标、色彩、文字表述、数据、代码、电子文档及其组合等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护。

      </p>
      <p> 5.2 用户使用佳伴服务只能在本协议以及相应的授权许可范围内使用悠蓝网络的知识产权，未经授权禁止任何超范围使用行为, 不论是否以商业为目的。

      </p>
      <p> 5.3 用户在使用甜识服务时所上传、发布、共享的内容,知识产权归用户所有。除非悠蓝网络收到相反通知，悠蓝网络将视该用户依法享有完整的著作权，并为内容的著作权人。

      </p>
      <p> 5.4
        用户点击同意本协议或通过佳伴服务上传、发布、共享内容的行为，即视为用户对悠蓝网络的授权，用户确认其将该内容以无偿、非独占性、永久性授权悠蓝网络使用、悠蓝网络合作伙伴或他们的用户有权在“佳伴网站”、“佳伴”移动客户端及悠蓝网络开放或合作的API/SDK
        平台（如有）进行搜索、收听、下载、分享、展示、传播。


      </p>
      <p class="f-b"> 6. 隐私政策

      </p>
      <p> 悠蓝网络非常重视贵方的隐私和个人信息， 建议未成年用户请监护人仔细阅读本隐私权政策。为此我们专门制定了《隐私政策》，希望向您说明我们在贵方使用佳伴服务时收集何种信息以及如何收集、使用和保护这些信息。
      </p>
      <p>
        在使用佳伴服务前，请您务必仔细阅读我们的隐私政策。除非贵方已阅读并接受本协议的全部条款，否则请贵方立即停止使用佳伴服务。一旦贵方选择注册了佳伴账号或以第三方平台账号登录或实际使用佳伴服务，即视为贵方已充分理解并接受本隐私政策。


      </p>
      <p class="f-b"> 7. 责任限制与不可抗力

      </p>
      <p> 7.1
        悠蓝网络按照现有技术和条件所能达到的现状向用户提供佳伴服务，并尽最大努力确保服务的安全性和稳定性。悠蓝网络不担保所提供的网络服务一定能满足用户的要求，也不担保提供的信息存储空间容量充足或其提供的网络服务不会被中断，且对服务的及时性、安全性、出错发生，以及信息是否能准确、及时、顺利的传送均不作任何担保，也不承担任何法律责任，但悠蓝网络将尽力减少因此给用户造成的损失和影响。

      </p>
      <p> 7.2 为了向用户提供优质服务，悠蓝网络有权不经用户同意而变更、改变、删除、增加佳伴服务，也可能暂停或彻底终止本服务，而不承担任何法律责任。

      </p>
      <p> 7.3
        用户理解，悠蓝网络审核用户注册信息以及内容仅仅是对此类信息的形式审查，悠蓝网络没有能力也不可能对用户的注册信息和上传的内容是否真实、是否侵权等方面进行实质审查。悠蓝网络对用户之注册信息及其上传、发布的内容不做任何担保、认可、认同，也不因此承担任何形式的法律责任。

      </p>
      <p> 7.4
        在使用佳伴服务过程中可能存在来自多方面的风险，包括威胁性、诽谤性、非法的、或侵犯他人合法权利的风险信息，用户需自行承担该风险。悠蓝网络对用户经由悠蓝网络提供的服务所获得的信息亦不做任何担保，包括真实性、合法性、非侵权性等，尤其是用户通过悠蓝网络提供的服务获得的商业广告信息、交友信息等。用户须自行甄别信息的真实性并谨慎预防可能存在的风险，因此而产生的任何直接、间接、偶然、附带或衍生的损失或损害，悠蓝网络不承担任何责任。

      </p>
      <p> 7.5
        用户理解，佳伴服务容易受到互联网安全问题的困扰，并由于互联网技术的不稳定性，可能遭遇基础电信运营商移动通信网络的故障、技术缺陷、覆盖范围限制、不可抗力、计算机病毒、黑客攻击、服务器系统崩溃等风险，上述风险可能导致佳伴服务中断或数据损失，悠蓝网络对此等不可抗力因素导致的损失不承担责任。


      </p>
      <p class="f-b"> 8. 通知

      </p>
      <p>所有发给用户的通知都可通过电子邮件、常规的信件或在佳伴服务内通过系统通知、私信、或页面公告等方式进行传送。悠蓝网络将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更等。


      </p>
      <p class="f-b"> 9. 法律责任

      </p>
      <p> 9.1
        如果发现或收到他人举报或投诉用户违反本协议约定的，悠蓝网络有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用全部或部分服务功能、暂时或永久冻结封禁账号、回收注销账号及清零等。

      </p>
      <p> 9.2 用户理解并同意，悠蓝网络有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。

      </p>
      <p> 9.3
        用户理解并同意，因用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何损失，包括但不限于：赔偿款、诉讼费、律师费、公证费、差旅费等直接、间接损失，用户应当全额赔偿悠蓝网络及该第三方，并使之免受损害。

      </p>
      <p> 9.4用户理解并同意，用户的赠送礼物行为属玩家个人行为，与平台无关，谨防上当受骗。


      </p>
      <p class="f-b"> 10.不可抗力及其他免责事由

      </p>
      <p> 10.1用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。
      </p>
      <p>
        不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我司将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，我司及合作单位在法律允许的范围内免责。

      </p>
      <p>
        10.2本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意
      </p>
      <p> 识，要注意加强密码保护，以免遭致损失和骚扰。

      </p>
      <p>
        10.3用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，我司不承担任何责任。

      </p>
      <p>
        10.4用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，我司不承担任何责任。

      </p>
      <p> 10.5用户理解并确认，我司需要定期或不定期地对“佳伴”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，我司无需为此承担任何责任，但我司应事先进行通告。

      </p>
      <p> 10.6我司依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成我司的义务或承诺，我司不能保证及时发现违法违规或违约行为或进行相应处理。

      </p>
      <p> 10.7用户理解并确认，对于我司向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，我司无需承担任何责任：
      </p>
      <p> (1) 我司向用户免费提供的服务；
      </p>
      <p> (2) 我司向用户赠送的任何产品或者服务。

      </p>
      <p>
        10.8在任何情况下，我司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“佳伴”或本服务而遭受的利润损失，承担责任（即使我司已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，我司对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用我司提供的服务而支付给我司的费用(如有)。


      </p>
      <p class="f-b">11.服务的变更、中断、终止

      </p>
      <p>
        11.1鉴于网络服务的特殊性，用户同意我司有权随时变更、中断或终止部分或全部的服务（包括收费服务）。我司变更、中断或终止的服务，我司应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，如果该用户已经向我司支付的颜币，我司应当按照该用户实际使用服务的情况扣除相应颜币之后将剩余的颜币退还用户的颜币账户中。

      </p>
      <p> 11.2如发生下列任何一种情形，我司有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：
      </p>
      <p> (1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
      </p>
      <p> (2) 用户违反相关法律法规或本协议的约定；
      </p>
      <p>(3) 按照法律规定或有权机关的要求；
      </p>
      <p> (4) 出于安全的原因或其他必要的情形。


      </p>
      <p class="f-b"> 12.其他

      </p>
      <p> 12.1我司郑重提醒用户注意本协议中免除我司责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。

      </p>
      <p> 12.2本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和我司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交我司住所地有管辖权的人民法院管辖。

      </p>
      <p> 12.3本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
    </div>
  </div>
</template>

<script>
import { getUrlQueryString } from '@/utils/util'

export default {
  name: 'ProtocolPage',
  data() {
    return {
      protocol: "用户协议",
      app_name: '佳伴'
    }
  },
  created() {
    document.title = '用户服务协议'
    console.log(getUrlQueryString('app_name'))
    this.app_name = getUrlQueryString('app_name')
  },
  methods: {}
}
</script>

<style lang="less">
#app {
  text-align: left;

  .pol {
    line-height: 80px;
    padding: 20px;
    box-sizing: border-box;
    .title {
      font-weight: bold;
      text-align: center;
      padding: 20px 0px;
    }
  }
}

</style>